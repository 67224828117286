import React from 'react'
import cx from 'classnames'

import './index.scss'
import Earth from '../../components/Earth'
import TitleWrapper from '../../components/TitleWrapper'
import genZHAge from '../../utils/genZHAge'

const HomeSection7 = () => {
  const prefix = 'home-section7'

  return (
    <section className={cx('group', prefix, `${prefix}-decorator`)}>
      <div className="grid">
        <TitleWrapper title="业务覆盖全国30+城市" bottomHomeSeparator />
        <div className="text">
          {`精耕细作${genZHAge()}年，再惠已服务超过20000个品牌，覆盖餐饮、休娱、美业、酒店等本地生活各个行业， 先后完成6轮由国内外顶级美元基金联合领投的融资，总融资额近3亿美元`}
        </div>
        <div className="companies-distribution-earth">
          <Earth />
        </div>
      </div>
    </section>
  )
}
export default HomeSection7
