import React from 'react'

import './index.scss'
import Layout from '../components/LayoutNew'
import { colorTheme } from '@homepages/shared/styles/variables'

import HomeSection1 from '../containers/HomeSection1'
import HomeSection2 from '../containers/HomeSection2'
import HomeSection3 from '../containers/HomeSection3'
import HomeSection4 from '../containers/HomeSection4'
import HomeSection5 from '../containers/HomeSection5'
import HomeSection6 from '../containers/HomeSection6'
import HomeSection7 from '../containers/HomeSection7'
import HomeSection8 from '../containers/HomeSection8'

const NewPage = () => {
  return (
    <Layout
      className="new-page"
      headerBackground={'rgb(252, 245, 243)'}
      fontColor={colorTheme}
    >
      <HomeSection1 />
      <HomeSection2 />
      <HomeSection5 />
      <HomeSection6 />
      <HomeSection8 />
      <HomeSection7 />
      <section className="grid grid-bottom bg-4">
        <HomeSection4 />
      </section>

      {/* 页脚 */}
      <HomeSection3 />
    </Layout>
  )
}
export default NewPage
