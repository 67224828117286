import React from 'react'
import cx from 'classnames'
import Img from 'gatsby-image'

import './index.scss'

import TitleWrapper from '../../components/TitleWrapper'
import { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const HomeSection6 = () => {
  const { marketingModeImg } = useStaticQuery(
    graphql`
      query {
        marketingModeImg: file(
          relativePath: { eq: "MarketingMode/marketing-mode.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 660, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const prefix = 'home-section6'

  const commonProps = {
    title: '多元化营销与销售模式',
    subTitle: (
      <Fragment>
        不再拘泥于传统营销形式: 针对商家特点,
        <br />
        进行多平台多模式自动化的饱和式营销、精准营销
      </Fragment>
    ),
    desc: [
      `阶梯式营销手段, 获取更高曝光和转化`,
      `高频、直触目标消费者, 减少获客成本`,
      `自动规划营销周期, 持续输出决策见解`,
      `定期复盘活动实效, 加强消费者留存度`,
    ],
    homeSeparatorPos: 'top',
  }

  return (
    <section className={cx('group', prefix, `${prefix}-decorator`)}>
      <div className="grid">
        <div className="marketing-mode">
          <div style={{ width: '100%' }}>
            <Img fluid={marketingModeImg.childImageSharp.fluid} />
          </div>
        </div>
        <div className="mobile">
          <TitleWrapper {...commonProps} type="left" />
        </div>
        <div className="not-mobile">
          <TitleWrapper {...commonProps} type="right" />
        </div>
      </div>
    </section>
  )
}

export default HomeSection6
