import React from 'react'
import './style.scss'

const ProspectsItem = ({ className, iconName, title, desc }) => {
  const prefix = 'prospects-item'

  return (
    <div className={`${prefix} ${className}`}>
      <div className={`iconfont ${iconName} ${prefix}-logo`} />
      <p className={`${prefix}-title`}>{title}</p>
      <p className={`${prefix}-sub-title`}>{desc}</p>
    </div>
  )
}

export default ProspectsItem
