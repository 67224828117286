import React from 'react'
import cx from 'classnames'
import Img from 'gatsby-image'

import './index.scss'

import TitleWrapper from '../../components/TitleWrapper'
import { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const HomeSection5 = () => {
  const { marketingDataImg } = useStaticQuery(
    graphql`
      query {
        marketingDataImg: file(relativePath: { eq: "MarketingMode/data.png" }) {
          relativeDirectory
          childImageSharp {
            fluid(maxWidth: 630, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const prefix = 'home-section5'

  return (
    <section className={cx('group', prefix)}>
      <div className="grid">
        <TitleWrapper
          title="智能大数据平台"
          subTitle={
            <Fragment>
              餐饮消费市场需求不断被激发，加速拓店和深入下沉市场成为重中之重
              <br />
              数字化变革将推动整个行业高效发展
            </Fragment>
          }
          desc={[
            `智能分析商户数据，精准定位商户问题`,
            `多维呈现消费行为，深度剖析消费需求`,
            `勾勒客群特征偏好，提升商家服务精度`,
            `推动客户数据沉淀，实现品牌规模效应`,
          ]}
          homeSeparatorPos="top"
          type="left"
        />
        <div className="marketing-mode">
          <div style={{ width: '100%' }}>
            <Img fluid={marketingDataImg.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  )
}
export default HomeSection5
