import { useEffect, useState } from 'react'
import { cssMediaQuery } from '../utils'

function useIsPhone() {
  const [isPhone, setIsPhone] = useState(false)

  useEffect(() => {
    cssMediaQuery('(max-width: 766.98px)', ({ matches } = {}) => {
      if (matches) {
        setIsPhone(true)
      } else {
        setIsPhone(false)
      }
    })
  }, [])

  return isPhone
}

export default useIsPhone
