import React, { useCallback, useEffect, useRef } from 'react'
import * as echarts from 'echarts/core'
import { GeoComponent } from 'echarts/components'
import { Addresses } from '@homepages/shared/constants/aboutUs'
import { colorPrimary, colorWhite } from '@homepages/shared/styles/variables'
import './style.scss'
import { debounce } from '../../utils'
// echarts.registerMap
import 'echarts-countries-js/echarts-countries-js/china'
import useIsPhone from '../../hooks/useIsPhone'

echarts.use([GeoComponent])

const planePath =
  'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z'

const Earth = () => {
  const echartsDom = useRef()
  const echartsRef = useRef()
  const dotRef = useRef()
  const isPhone = useIsPhone()

  useEffect(() => {
    if (echartsRef.current && isPhone) {
      echartsRef.current.setOption({
        series: [
          {
            ...(dotRef.current || {}),
            data: Addresses.map(function(dataItem) {
              return {
                name: dataItem.name,
                value: dataItem.addressCode, // 点的位置
                symbolSize: 2,
              }
            }),
          },
        ],
      })
    }
  }, [isPhone])

  const initData = async () => {
    const color = ['#ed3737'] // 自定义图中要用到的颜色
    const series = []
    const dots = Addresses.slice()
    const rootData = dots.shift()
    const lineData = dots.map(({ addressCode }) => {
      return [rootData.addressCode, addressCode]
    })
    dotRef.current = {
      id: 'dot',
      // 散点效果
      type: 'effectScatter',
      coordinateSystem: 'geo', // 表示使用的坐标系为地理坐标系
      zlevel: 3,
      rippleEffect: {
        brushType: 'stroke', // 波纹绘制效果
      },
      label: {
        position: 'top',
        color: colorPrimary,
      },
      emphasis: {
        label: {
          show: true,
          formatter: '{b}',
        },
      },
      itemStyle: {
        color: color[0],
      },
      data: Addresses.map(function(dataItem) {
        return {
          name: dataItem.name,
          value: dataItem.addressCode, // 点的位置
          symbolSize: 10,
        }
      }),
    }

    series.push(
      {
        // 白色航线特效图
        type: 'lines',
        zlevel: 1, // 用于分层，z-index的效果
        effect: {
          show: true, // 动效是否显示
          period: 6, // 特效动画时间
          trailLength: 0.7, // 特效尾迹的长度
          color: colorWhite, // 特效颜色
          symbolSize: 3, // 特效大小
        },
        lineStyle: {
          // 正常情况下的线条样式
          color: color[0],
          width: 0, // 因为是叠加效果，要是有宽度，线条会变粗，白色航线特效不明显
          curveness: -0.2, // 线条曲度
        },
        data: lineData, // 特效的起始、终点位置
      },
      {
        // 小飞机航线效果
        type: 'lines',
        zlevel: 2,
        symbolSize: 10,
        effect: {
          show: true,
          period: 6,
          trailLength: 0,
          symbol: planePath, // 特效形状，可以用其他svg pathdata路径代替
          symbolSize: 10,
        },
        lineStyle: {
          color: color[0],
          width: 1,
          opacity: 0.6,
          curveness: -0.2,
        },
        data: lineData,
        // 特效的起始、终点位置，一个二维数组，相当于coords: convertData(item[1])
      },
      dotRef.current
    )

    echartsRef.current.setOption({
      geo: {
        map: 'china', // 与引用进来的地图js名字一致
        zoom: 1.2,
        roam: false, // 启用缩放平移
        // center: geoCoordMap['上海'],
        itemStyle: {
          // 每个区域的样式
          areaColor: colorWhite,
          borderColor: colorPrimary,
          opacity: 0.5,
        },
        label: {
          show: false,
        },
        emphasis: {
          itemStyle: {
            areaColor: colorWhite,
          },
          label: {
            show: false,
          },
        },
        scaleLimit: {
          min: 0.5,
          max: 7,
        },
        silent: true,
      },
      series, // 将之前处理的数据放到这里
      textStyle: {
        fontSize: 12,
      },
    })
  }

  const getEl = el => {
    if (el) {
      echartsDom.current = el
      echartsRef.current = echarts.init(el)
      initData(echartsRef.current)
      // 缩放
      window.addEventListener(
        'resize',
        debounce(() => {
          const res = el.getBoundingClientRect()
          res.height && echartsRef.current.resize(res)
        }),
        false
      )
    }
  }

  return (
    <div className="square-box">
      <div ref={getEl} className="content" id="main" />
    </div>
  )
}
export default Earth
