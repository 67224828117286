import React from 'react'
import cx from 'classnames'
import Img from 'gatsby-image'

import './index.scss'

import TitleWrapper from '../../components/TitleWrapper'
import { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const HomeSection8 = () => {
  const { marketingDataImg } = useStaticQuery(
    graphql`
      query {
        marketingDataImg: file(
          relativePath: { eq: "MarketingMode/solution.png" }
        ) {
          relativeDirectory
          childImageSharp {
            fluid(maxWidth: 630, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const prefix = 'home-section8'

  return (
    <section className={cx('group', prefix, `${prefix}-decorator`)}>
      <div className="grid">
        <TitleWrapper
          title="全行业解决方案"
          subTitle={
            <Fragment>
              本地生活全行业赋能：充分挖掘本地生活各行业痛点，
              <br />
              依托专业工具，提升全行业用户体验和服务品质
            </Fragment>
          }
          desc={[
            `系统深挖行业生成出详尽方案`,
            `专业工具精心涵盖项目全流程`,
            `注重提升商家体验和服务效率`,
            `满足不同行业客户的多样需求`,
          ]}
          homeSeparatorPos="top"
          type="left"
        />
        <div className="solution">
          <div style={{ width: '100%' }}>
            <Img fluid={marketingDataImg.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  )
}
export default HomeSection8
