import React from 'react'
import cx from 'classnames'
import saasSolutionImg from '../../images/home/solution/saas-solution.png'

import './index.scss'

const HomeSection2 = () => {
  const prefix = 'home-section2'

  return (
    <section className={cx('group', prefix, `${prefix}-decorator`)}>
      <div className="group grid solution-block">
        <h2 className="block-title">一站式解决方案</h2>
        <div className="block-desc">
          再惠向商家提供多渠道前端。通过再惠的多渠道平台，商家能够直接触达消费者，同时实现与抖音、b站等社交流量平台和美团点评、口碑等大型电商平台的连接，实现流量和交易的高效撮合
        </div>
        <div className="block-img">
          <img src={saasSolutionImg} alt="一站式解决方案" />
        </div>
      </div>
    </section>
  )
}
export default HomeSection2
