/**
 * 公司地址
 */
export const Addresses = [
  {
    name: '上海总部',
    address: '上海市普陀区中江路118弄22号海亮大厦A座7楼、11楼、12楼、14楼',
    phone: '021-20435746、021-60523213',
    // addressCode 是百度的 https://api.map.baidu.com/lbsapi/getpoint/index.html
    addressCode: [120.306613, 31.583494],
    contactUs: {
      localImgKey: '上海',
      // uri用的是高德的单点标注
      // 步骤:
      // 1.先到 https://lbs.amap.com/tools/picker 搜索分公司地址对应坐标
      // 2.再到 https://lbs.amap.com/api/uri-api/guide/mobile-web/point 在'根据经纬度坐标标注地点'中填入获取到的坐标、地址名称等参数即可生成跳转链接
      uri: '//uri.amap.com/marker?position=121.393343,31.22242&name=海亮大厦A座7楼、11楼、12楼、14楼&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  {
    name: '北京分公司',
    address: '北京市朝阳区朝阳门外大街乙6号朝外SOHO写字楼D座10层1027',
    addressCode: [116.461216, 39.925725],
    contactUs: {
      localImgKey: '北京',
      uri: '//uri.amap.com/marker?position=116.454812,39.920432&name=北京市朝阳区朝阳门外大街乙6号朝外SOHO写字楼D座10层1027&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  {
    name: '重庆分公司',
    address: '重庆市渝中区解放碑街道平安国际金融中心3302',
    addressCode: [106.587116, 29.565185],
    contactUs: {
      localImgKey: '重庆',
      uri: '//uri.amap.com/marker?position=106.587116,29.5651858&name=平安国际金融中心3302&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 江苏
  {
    name: '苏州分公司',
    address: '江苏省苏州市苏州大道西路205号尼盛广场1幢702-703室',
    phone: '0512-81660112',
    addressCode: [120.665686, 31.314118],
    contactUs: {
      localImgKey: '苏州',
      uri: '//uri.amap.com/marker?position=120.665686,31.314118&name=尼盛广场1幢702-703室&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  {
    name: '南京分公司',
    address: '南京市雨花区铁心桥街道未瞰科技大厦601室',
    phone: '025-83350995',
    addressCode: [118.768942, 31.989822],
    contactUs: {
      localImgKey: '南京',
      uri: '//uri.amap.com/marker?position=118.76,31.98&name=雨花区铁心桥街道未瞰科技大厦601室&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  {
    name: '无锡分公司',
    address: '江苏省无锡市梁溪区中山路359号东方广场B座21层AB单元',
    phone: '0510-82712772',
    addressCode: [120.305031, 31.582981],
    contactUs: {
      localImgKey: '无锡',
      uri: '//uri.amap.com/marker?position=120.30,31.583&name=中山路359号东方广场B座21层AB单元&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  {
    name: '常州分公司',
    address: '江苏省常州市钟楼区大庙弄2号新闻大厦802室',
    phone: '',
    addressCode: [119.961214, 31.786243],
    contactUs: {
      localImgKey: '常州',
      uri: '//uri.amap.com/marker?position=119.954659,31.780507&name=大庙弄2号新闻大厦802室&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 浙江
  {
    name: '杭州分公司',
    address: '杭州市西湖区学院路28号德力西大厦1号楼1602、1603、1604室',
    phone: '0571-88307308',
    contactUs: {
      localImgKey: '杭州',
      uri: '//uri.amap.com/marker?position=120.13,30.27&name=杭州市西湖区学院路28号德力西大厦1号楼1602、1603、1604室&src=mypage&coordinate=gaode&callnative=0',
    },
    addressCode: [120.137337, 30.279158],
  },
  {
    name: '宁波分公司',
    address: '浙江省宁波市海曙区江厦街道灵桥路777号中国人寿大厦1605室',
    phone: '0574-87115616',
    addressCode: [121.563693, 29.872532],
    contactUs: {
      localImgKey: '宁波',
      uri: '//uri.amap.com/marker?position=121.56,29.87&name=浙江省宁波市海曙区江厦街道灵桥路777号中国人寿大厦1605室&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 湖北
  {
    name: '武汉分公司',
    address: '湖北省武汉市江汉区万松街淮海路泛海国际SOHO城6栋2107室',
    phone: '027-63603830',
    addressCode: [114.254295, 30.60483],
    contactUs: {
      localImgKey: '武汉',
      uri: '//uri.amap.com/marker?position=114.247262,30.599104&name=泛海国际SOHO城6栋1803室&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 福建
  {
    name: '厦门分公司',
    address: '厦门市湖里区塘边宝龙天地招商中心305单元',
    phone: '0592-5520185',
    addressCode: [118.129726, 24.512373],
    contactUs: {
      localImgKey: '厦门',
      uri: '//uri.amap.com/marker?position=118.123179,24.506716&name=塘边宝龙天地招商中心305单元&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 陕西
  {
    name: '西安分公司',
    address: '陕西省西安市碑林区太乙路泛太国际B座汇智空间708室',
    phone: '029-85222873',
    addressCode: [108.978238, 34.246882],
    contactUs: {
      localImgKey: '西安',
      uri: '//uri.amap.com/marker?position=108.97,34.243&name=陕西省西安市碑林区太乙路泛太国际B座汇智空间708室&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 湖南
  {
    name: '长沙分公司',
    address: '湖南省长沙市芙蓉区五一大道中天写字楼19046',
    addressCode: [112.982674, 28.195657],
    contactUs: {
      localImgKey: '长沙',
      uri: '//uri.amap.com/marker?position=112.982674,28.195657&name=五一大道中天写字楼19046&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 四川
  {
    name: '成都分公司',
    address: '四川省成都市成华区府青路二段2号1栋凯龙财富大厦21楼',
    phone: '028-84739768',
    addressCode: [104.10054, 30.683909],
    contactUs: {
      localImgKey: '成都',
      uri: 'URL: //uri.amap.com/marker?position=104.094174,30.678199&name=栋凯龙财富大厦21楼&src=&coordinate=gaode&callnative=0',
    },
  },
  // 天津
  {
    name: '天津分公司',
    address: '天津市河西区解放南路256号泰达大厦25层H座',
    addressCode: [117.227962, 39.119313],
    contactUs: {
      localImgKey: '天津',
      uri: '//uri.amap.com/marker?position=117.221423,39.113109&name=泰达大厦21层B座&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 山东
  {
    name: '青岛分公司',
    address: '青岛市市北区卓越世纪中心3座1114-1115室',
    addressCode: [120.384438, 36.096053],
    contactUs: {
      localImgKey: '青岛',
      uri: '//uri.amap.com/marker?position=120.378021,36.089847&name=卓越世纪中心3座1114-1115室&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // {
  //   name: '济南分公司',
  //   address: '山东省济南市历城区花园路二建融基大厦2203室',
  // 	addressCode: [117.066117,36.688196],
  //   contactUs: {
  //     localImgKey: '济南',
  //     uri: '//uri.amap.com/marker?position=117.059895,36.682364&name=二建融基大厦2203室&src=mypage&coordinate=gaode&callnative=0',
  //   }
  // },
  // 深圳
  {
    name: '深圳分公司',
    address: '广东省深圳市福田区车公庙天安数码城创新科技广场一期A座1005',
    addressCode: [114.037324, 22.539525],
    contactUs: {
      localImgKey: '深圳',
      uri: '//uri.amap.com/marker?position=114.030961,22.533577&name=天安数码城创新科技广场一期A座1005&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  {
    name: '广州分公司',
    address: '广州市天河区天河南街道体育西路189号城建大厦25层25ABCDE房',
    addressCode: [113.327358, 23.145049],
    contactUs: {
      localImgKey: '广州',
      uri: '//uri.amap.com/marker?position=113.327358,23.145049&name=广州市天河区天河南街道体育西路189号城建大厦25层25ABCDE房&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 福建
  {
    name: '福州分公司',
    address: '福州市鼓楼区温泉街道五一北路1号力宝天马广场1102',
    addressCode: [119.315467, 26.092795],
    contactUs: {
      localImgKey: '福建',
      uri: '//uri.amap.com/marker?position=119.308912,26.086576&name=宝天马广场11楼1102&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 安徽
  {
    name: '合肥分公司',
    address: '安徽省合肥市瑶海区新交通大厦1107室',
    addressCode: [117.308061, 31.869302],
    contactUs: {
      localImgKey: '合肥',
      uri: '//uri.amap.com/marker?position=117.30,31.86&name=安徽省合肥市瑶海区新交通大厦1107室&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 河南
  {
    name: '郑州分公司',
    address: '郑州市金水区花园路122号建业凯旋广场A座1809',
    addressCode: [113.687196, 34.799086],
    contactUs: {
      localImgKey: '郑州',
      uri: '//uri.amap.com/marker?position=113.687196,34.799086&name=郑州市金水区花园路122号建业凯旋广场A座1809&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  {
    name: '新加坡分公司',
    address: '140 ROBINSON ROAD, #18-01 TAHIR BUILDING, SINGAPORE 068907',
    addressCode: [120.306613, 31.583494],
    contactUs: {
      localImgKey: '上海',
      uri: '//uri.amap.com/marker?position=121.393343,31.22242&name=海亮大厦A座7楼、11楼、12楼、14楼&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 昆明
  {
    name: '昆明分公司',
    address: '云南省昆明市西山区云投中心B3栋715号',
    addressCode: [102.6876, 25.049965],
    contactUs: {
      localImgKey: '昆明',
      uri: '//uri.amap.com/marker?position=102.682515,25.043815&name=西山区云投中心B3栋715号&src=mypage&coordinate=gaode&callnative=0',
    },
  },
  // 南昌
  {
    name: '南昌',
    address: '江西省南昌市红谷滩新区红谷中心区控规B-22号科研测试基地C单元',
    addressCode: [115.865221, 28.695224],
    contactUs: {
      localImgKey: '南昌',
      uri: '//uri.amap.com/marker?position=115.865221, 28.695224&name=park&src=mypage&coordinate=gaode&callnative=0',
    },
  },
]

export const Honor = [
  {
    img: require('@homepages/zaihui/src/images/aboutUs/honor/朱辰昊-36氪36位36岁以下了不起的创业者.jpg'),
    desc: '朱辰昊-36氪36位36岁以下了不起的创业者',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/honor/202182-17453.jpeg'),
    desc: '李晓捷-财富40位40岁以下的商界精英',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/honor/202182-1753.jpeg'),
    desc: '赵洋-创业邦30位30岁以下创业新贵',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/honor/陈振华-2020年度智慧餐饮风云人物奖.jpg'),
    desc: '陈振华-2020年度智慧餐饮风云人物奖',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/honor/2020全球零售科技创新TOP50-再惠.png'),
    desc: '2020全球零售科技创新TOP50',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/honor/2020中国新经济企业活力100指数榜.jpg'),
    desc: '2020中国新经济企业活力100指数榜',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/honor/36氪-最具竞争力企业top100.png'),
    desc: '36氪-最具竞争力企业top100',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/honor/2020新消费增长企业榜单top50.jpg'),
    desc: '2020新消费增长企业榜单top50',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/honor/20200620-科创百强.png'),
    desc: '2020 科创百强',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/honor/安永复旦中国最具潜力企业2020榜单.jpg'),
    desc: '安永复旦中国最具潜力企业2020榜单',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/honor/2020德勤明日之星.jpg'),
    desc: '2020德勤明日之星',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/honor/2020大中华区新业态经济最佳实践平台.png'),
    desc: '2020大中华区新业态经济最佳实践平台',
  },
]

export const Certificate = [
  {
    img: require('@homepages/zaihui/src/images/aboutUs/certificate/跨国公司研发中心证书.jpg'),
    desc: '跨国公司研发中心证书',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/certificate/上海市守合同重信用企业证书.jpg'),
    desc: '上海市守合同重信用企业证书',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/certificate/高新技术企业证书.jpg'),
    desc: '高新技术企业证书',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/certificate/软件企业证书2022-2023.jpg'),
    desc: '软件企业证书2022-2023',
  },
  {
    img: require('@homepages/zaihui/src/images/aboutUs/certificate/软件产品证书.jpg'),
    desc: '软件产品证书',
  },
]
