import React from 'react'
import cx from 'classnames'

import './index.scss'

import TitleWrapper from '../../components/TitleWrapper'
import ProspectsItem from '../../components/ProspectsItem'
import { Fragment } from 'react'
import genZHAge from '../../utils/genZHAge'

const HomeSection4 = () => {
  const prospects = [
    {
      iconName: 'z-a-131-food',
      title: '专注本地生活领域',
      desc: `精耕${genZHAge()}年，多渠道精细化用户触达，赋能多方应用场景`,
    },
    {
      iconName: 'z-a-132-date',
      title: '大数据精准 AI 营销',
      desc: '深度挖掘数据，分析了解消费行为，轻松获取最新信息',
    },
    {
      iconName: 'z-a-133-team',
      title: '系统化专业团队',
      desc: '专业团队成员构成，数年行业服务经验',
    },
    {
      iconName: 'z-a-134-cooperation',
      title: '数万合作成功客户',
      desc: '累计服务数万商家，平台官方指定服务商',
    },
  ]

  const prefix = 'home-section4'

  return (
    <section className="group">
      <div className={cx('grid', 'first', prefix)}>
        <div className="title-pc">
          <TitleWrapper title="选择再惠服务，成就品牌愿景" type="center" />
        </div>
        <div className="title-mobile">
          <TitleWrapper
            title={
              <Fragment>
                选择再惠服务
                <br />
                成就品牌愿景
              </Fragment>
            }
            type="center"
          />
        </div>
        <div className={cx(`${prefix}-content-box`)}>
          <div className="future-prospects">
            {prospects.map(item => (
              <Fragment key={item.title}>
                <ProspectsItem
                  className="item"
                  iconName={item.iconName}
                  title={item.title}
                  desc={item.desc}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
export default HomeSection4
