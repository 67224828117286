const genZHAge = () => {
  const curDate = new Date()
  const startDate = new Date('2015/01/01')

  const curYear = curDate.getFullYear()
  const startYear = startDate.getFullYear()

  const curMonth = curDate.getMonth() + 1
  const startMonth = startDate.getMonth() + 1

  const curDay = curDate.getDate()
  const startDay = startDate.getDate()

  let year = curYear - startYear

  // 当前月小于目标月
  if (curMonth < startMonth) {
    return year - 1
  } else if (curMonth === startMonth && curDay < startDay) {
    // 当前天小于目标天
    return year - 1
  }
  return year
}

export default genZHAge
