import React from 'react'
import cx from 'classnames'
import { graphql, Link, useStaticQuery } from 'gatsby'
import './index.scss'
import TitleWrapper from '../../components/TitleWrapper'
import Img from 'gatsby-image'

const HomeSection1 = () => {
  const { pic1 } = useStaticQuery(graphql`
    query {
      pic1: file(relativePath: { eq: "home/home-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 508, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const prefix = 'home-section1'

  return (
    <section className={cx('group', prefix)}>
      <div className={cx('section-container')}>
        <div className={cx('title-wrapper', `${prefix}-title`)}>
          <TitleWrapper
            title={`本地生活领域的\n全栈电商解决方案`}
            subTitle="致力于让每个老板都能轻松开店"
            withSeparator={false}
            type="left"
          />
          <p className="desc">
            以本地生活商户的基础线上开店软件，拓展到线上线下综合解决方案服务商，帮助本地商家能更独立、更有效地经营，更专注于创造美好，为用户提供更多和更优的选择。
          </p>
          <Link
            className={cx('btn', 'btn-size-1', `${prefix}-btn`, 'black-hover')}
            to={'/about'}
          >
            了解更多 <i className="iconfont z-a-012-right" />
          </Link>
        </div>
        <div className="pic-wrapper">
          <Img
            fluid={pic1.childImageSharp.fluid}
            alt="首页img"
            style={{ maxWidth: '508px', maxHeight: '451px' }}
          />
        </div>
      </div>
    </section>
  )
}
export default HomeSection1
